<template>
  <span data-cy="select-users-role-ro">{{ userRole }}</span>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "UserRoleFieldRO",
  props: {
    roleValue: {
      type: Number,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    userRole() {
      let role = APP_CONSTANTS.USER_ROLES.find((item) => {
        return item.value === this.roleValue;
      });
      return this.$t(`users.roles.${role.name}`);
    },
  },
};
</script>
