<template>
  <v-skeleton-loader :loading="status.loading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="Users-show">
      <template v-if="objectId">
        <v-container class="pa-0 mb-4">
          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.userName") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.userName }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.name") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.name }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.surnames") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.surnames }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.role") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>
                <UserRoleFieldRO :roleValue="user.role"></UserRoleFieldRO>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="authCan('see.admin-options')">
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.email") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.email }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.phone") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.phone }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.address") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.address }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.city") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.city }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.postalCode") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.postalCode }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">
                {{ $t("common.countryIso3166_a2") }}:
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <div>
                <CountryPickerFieldRO
                  :countryValue="user.countryIso3166_a2"
                ></CountryPickerFieldRO>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.languageIso639_1") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>
                <LanguagePickerFieldRO
                  :languageValue="user.languageIso639_1"
                ></LanguagePickerFieldRO>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <div class="label-show">{{ $t("common.description") }}:</div>
            </v-col>
            <v-col cols="12" md="10">
              <div>{{ user.description }}</div>
            </v-col>
          </v-row>
        </v-container>

        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <v-btn
            v-if="
              authCanByUserId('editOwn.users', this.objectId) ||
              authCan('editOthers.users')
            "
            v-bind="propsButtonAction"
            :to="{ name: 'users-edit', params: { id: objectId } }"
            data-cy="button-edit"
            >{{ $t("common.edit") }}</v-btn
          >
        </div>
      </template>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import UserRoleFieldRO from "@/modules/users/bits/UserRoleFieldRO";
import CountryPickerFieldRO from "@/modules/base/bits/CountryPickerFieldRO";
import LanguagePickerFieldRO from "@/modules/base/bits/LanguagePickerFieldRO";

export default {
  name: "UsersShow",
  mixins: [Permissions, ComponentStatus],
  components: {
    UserRoleFieldRO,
    CountryPickerFieldRO,
    LanguagePickerFieldRO,
  },
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
  }),
  computed: {
    // 2) When the user id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the user data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    user() {
      return this.$store.getters["users/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the user id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.$store
          .dispatch("users/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
            this.$store.commit("status/showError");
          });
      },
    },
  },
  created() {},
};
</script>
